/* eslint-disable max-lines */
/* eslint-disable no-use-before-define */
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeclarant } from '../../../stores/declarantSlice';

import { Autocomplete, Input, Button } from '../../../components';
import { saveDeclarationDataLRN, savePartialDataToDeclaration, selectDetails } from '../../../stores/declarationsSlice';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../styles/common';
import { retrieveCodes } from '../../../stores/settingsSlice';

interface IDeclarationDetailsStakeholdersProps {
  editDisabled: boolean;
}

const DeclarationDetailsCustomsTreatment: React.FC<IDeclarationDetailsStakeholdersProps> = (props) => {
  const dispatch = useDispatch();
  const declarationDetails = useSelector(selectDetails);
  const declarant = useSelector(selectDeclarant);

  const formik = useFormik({
    initialValues: {
      messageInfo: {
        methodOfPayment: declarationDetails?.messageInfo?.methodOfPayment,
        deferredPayment: declarationDetails?.messageInfo?.deferredPayment,
        deferredPaymentVAT: declarationDetails?.messageInfo?.deferredPaymentVAT,
        methodOfPaymentVAT: declarationDetails?.messageInfo?.methodOfPaymentVAT,
      },
      valuationInformationTaxes: {
        chargesimport: {
          CustomsCharges: {
            charges: declarationDetails?.valuationInformationTaxes?.chargesimport?.CustomsCharges?.charges,
            currency: declarationDetails?.valuationInformationTaxes?.chargesimport?.CustomsCharges?.currency,
          },
          VATcharges: {
            charges: declarationDetails?.valuationInformationTaxes?.chargesimport?.VATcharges?.charges,
            currency: declarationDetails?.valuationInformationTaxes?.chargesimport?.VATcharges?.currency,
          },
        },
        valuationMethod: declarationDetails?.valuationInformationTaxes?.valuationMethod,
      },
      consignment: {
        transportCost: {
          amount: declarationDetails?.consignment?.transportCost?.amount,
          currencyCode: declarationDetails?.consignment?.transportCost?.currencyCode,
        },
      },
    },
    onSubmit: async (values) => {
      dispatch(
        declarationDetails.generalInfo.group === 'H1'
          ? saveDeclarationDataLRN({
              lrn: declarationDetails.messageInfo.LRN,
              sequence: declarationDetails.goodShipmentGlobal.sequence,
              partialDeclaration: values,
            })
          : savePartialDataToDeclaration(declarationDetails._id, values),
      );
    },
    enableReinitialize: false,
  });

  return (
    <FormContainer paddingRight={30} onSubmit={formik.handleSubmit}>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Customs treatment</FormSectionTitle>
        <FormRow>
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Payment Method"
            fetchOptions={(search: string) => retrieveCodes('CL104', search, declarant.language)}
            onChange={(selectedOption) => formik.setFieldValue('messageInfo.methodOfPayment', selectedOption?.value)}
            value={{
              value: formik.values.messageInfo.methodOfPayment,
              label: formik.values.messageInfo.methodOfPayment,
            }}
            disabled={props.editDisabled}
          />
          <Input
            name="messageInfo.deferredPayment"
            placeholder="Deferred payment "
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.messageInfo.deferredPayment}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            placeholder="Account Holder"
            value={
              declarationDetails.documents.additionalFiscalReference.find((ref) => ref.role === 'FR4')
                ?.vatIdentificationNumber
            }
            width={30}
            widthUnit="%"
            disabled
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="VAT Payment Method"
            fetchOptions={(search: string) => retrieveCodes('CL104', search, declarant.language)}
            onChange={(selectedOption) => formik.setFieldValue('messageInfo.methodOfPaymentVAT', selectedOption?.value)}
            value={{
              value: formik.values.messageInfo.methodOfPaymentVAT,
              label: formik.values.messageInfo.methodOfPaymentVAT,
            }}
            disabled={props.editDisabled}
          />
          <Input
            name="messageInfo.deferredPayment"
            placeholder="Deferred payment VAT"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.messageInfo.deferredPaymentVAT}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Valuation method"
            fetchOptions={(search: string) => retrieveCodes('CL104', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('valuationInformationTaxes.valuationMethod', selectedOption?.value)
            }
            value={{
              value: formik.values.valuationInformationTaxes.valuationMethod,
              label: formik.values.valuationInformationTaxes.valuationMethod,
            }}
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>
      {declarationDetails.generalInfo.group === 'H1' ? (
        <>
          <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
            <FormSectionTitle noTopBorder>Customs charges</FormSectionTitle>
            <FormRow>
              <Input
                name="valuationInformationTaxes.chargesimport.CustomsCharges.charges"
                placeholder="Customs charges"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.valuationInformationTaxes.chargesimport.CustomsCharges.charges}
                width={50}
                widthUnit="%"
                disabled={props.editDisabled}
              />
              <Autocomplete
                width={50}
                widthUnit="%"
                placeholder="Currency"
                fetchOptions={(search: string) => retrieveCodes('CL048', search, declarant.language)}
                onChange={(selectedOption) =>
                  formik.setFieldValue(
                    'valuationInformationTaxes.chargesimport.CustomsCharges.currency',
                    selectedOption?.value,
                  )
                }
                value={{
                  value: formik.values.valuationInformationTaxes.chargesimport.CustomsCharges.currency,
                  label: formik.values.valuationInformationTaxes.chargesimport.CustomsCharges.currency,
                }}
                disabled={props.editDisabled}
              />
            </FormRow>
          </FormSection>
          <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
            <FormSectionTitle noTopBorder>VAT charges</FormSectionTitle>
            <FormRow>
              <Input
                name="valuationInformationTaxes.chargesimport.VATcharges.charges"
                placeholder="VAT charges"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.valuationInformationTaxes.chargesimport.VATcharges.charges}
                width={50}
                widthUnit="%"
                disabled={props.editDisabled}
              />
              <Autocomplete
                width={50}
                widthUnit="%"
                placeholder="Currency"
                fetchOptions={(search: string) => retrieveCodes('CL048', search, declarant.language)}
                onChange={(selectedOption) =>
                  formik.setFieldValue(
                    'valuationInformationTaxes.chargesimport.VATcharges.currency',
                    selectedOption?.value,
                  )
                }
                value={{
                  value: formik.values.valuationInformationTaxes.chargesimport.VATcharges.currency,
                  label: formik.values.valuationInformationTaxes.chargesimport.VATcharges.currency,
                }}
                disabled={props.editDisabled}
              />
            </FormRow>
          </FormSection>
        </>
      ) : (
        <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
          <FormSectionTitle noTopBorder>Transport and insurance costs</FormSectionTitle>
          <FormRow>
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Currency code"
              fetchOptions={(search: string) => retrieveCodes('CL048', search, declarant.language)}
              onChange={(selectedOption) =>
                formik.setFieldValue('consignment.transportCost.currencyCode', selectedOption?.value)
              }
              value={{
                value: formik.values.consignment.transportCost.currencyCode,
                label: formik.values.consignment.transportCost.currencyCode,
              }}
              disabled={props.editDisabled}
            />
            <Input
              name="consignment.transportCost.amount"
              placeholder="Amount"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.consignment.transportCost.amount}
              width={50}
              widthUnit="%"
              disabled={props.editDisabled}
            />
          </FormRow>
        </FormSection>
      )}

      {!props.editDisabled && (
        <div>
          <Button type="submit" primary key="save-modal-btn">
            Save
          </Button>
        </div>
      )}
    </FormContainer>
  );
};

export default DeclarationDetailsCustomsTreatment;
