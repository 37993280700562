import { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { StatusIcons } from '../../assets';

import { TabSelector } from '../../styles/common';
import { Tab } from './tab.component';
import * as Style from './tabs.style';

interface ITabsProps {
  children: ReactElement[];
  selectedTab?: any;
  onSelectedTabChange?(index: any): void;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
}

export const Tabs: React.FC<ITabsProps> = (props) => {
  const [selectedTab, setSelectedTab] = useState<any | number>(props.selectedTab || 0);

  useEffect(() => {
    if (props.selectedTab && props.selectedTab !== selectedTab) {
      setSelectedTab(props.selectedTab);
    }
  }, [props.selectedTab]);

  useEffect(() => {
    if (props.onSelectedTabChange && props.selectedTab !== selectedTab) {
      props.onSelectedTabChange(selectedTab);
    }
  }, [selectedTab]);

  const ErrorTabIcon = ({ show }) =>
    show ? <img style={{ marginRight: 5 }} src={StatusIcons.RED_WARNING} alt="" /> : null;

  return (
    <>
      <Style.Nav style={props.style || {}}>
        {props.children
          .filter((child) => child?.type === Tab)
          .map((child, index) => (
            <TabSelector
              key={index}
              isActive={selectedTab === (child.props.id || index)}
              onClick={() => setSelectedTab(child.props.id || index)}
            >
              <ErrorTabIcon show={child.props.hasError} /> {child.props.label}
            </TabSelector>
          ))}
      </Style.Nav>
      <Style.Content style={props.contentStyle}>
        {props.children.find((child, index) => selectedTab === (child.props.id || index))}
      </Style.Content>
    </>
  );
};
