/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable no-use-before-define */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeclarant } from '../../../stores/declarantSlice';
import { retrieveCodes } from '../../../stores/settingsSlice';

import { StatusIcons } from '../../../assets';
import { Autocomplete, Button, Input, Switcher } from '../../../components';
import {
  checkImporterEori,
  checkIntracomVat,
  saveDeclarationDataLRN,
  savePartialDataToDeclaration,
  selectDetails,
} from '../../../stores/declarationsSlice';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../styles/common';

interface IDeclarationDetailsStakeholdersProps {
  editDisabled: boolean;
}

const DeclarationDetailsStakeholders: React.FC<IDeclarationDetailsStakeholdersProps> = (props) => {
  const dispatch = useDispatch();
  const declarationDetails = useSelector(selectDetails);

  const declarant = useSelector(selectDeclarant);

  const formik = useFormik({
    initialValues: {
      stakeholders: {
        exporter: {
          header: declarationDetails?.stakeholders?.exporter?.header,
          name: declarationDetails?.stakeholders?.exporter.name || '',
          identificationNumber: declarationDetails?.stakeholders?.exporter?.identificationNumber || '',
          address: {
            country: declarationDetails?.stakeholders?.exporter?.address?.country || '',
            street: declarationDetails?.stakeholders?.exporter?.address?.street || '',
            postcode: declarationDetails?.stakeholders?.exporter?.address?.postcode || '',
            city: declarationDetails?.stakeholders?.exporter?.address?.city || '',
          },
        },
        importer: {
          name: declarationDetails?.stakeholders?.importer?.name || '',
          identificationNumber: declarationDetails?.stakeholders?.importer?.identificationNumber || '',
          address: {
            street: declarationDetails?.stakeholders?.importer?.address?.street || '',
            postcode: declarationDetails?.stakeholders?.importer?.address?.postcode || '',
            city: declarationDetails?.stakeholders?.importer?.address?.city || '',
            country: declarationDetails?.stakeholders?.importer?.address?.country || '',
          },
        },
        intracom: {
          name: declarationDetails?.stakeholders?.intracom?.name,
          identificationNumber: declarationDetails?.stakeholders?.intracom?.identificationNumber,
          address: {
            street: declarationDetails?.stakeholders?.intracom?.address?.street || '',
            postcode: declarationDetails?.stakeholders?.intracom?.address?.postcode || '',
            city: declarationDetails?.stakeholders?.intracom?.address?.city || '',
            country: declarationDetails?.stakeholders?.intracom?.address?.country || '',
          },
        },
        additionalSupplyChainActor: {
          header: declarationDetails?.stakeholders?.additionalSupplyChainActor?.header,
          name: declarationDetails?.stakeholders?.additionalSupplyChainActor?.name,
          identificationNumber: declarationDetails?.stakeholders?.additionalSupplyChainActor?.identificationNumber,
          role: declarationDetails?.stakeholders?.additionalSupplyChainActor?.role,
          address: {
            street: declarationDetails?.stakeholders?.additionalSupplyChainActor?.address?.street || '',
            postcode: declarationDetails?.stakeholders?.additionalSupplyChainActor?.address?.postcode || '',
            city: declarationDetails?.stakeholders?.additionalSupplyChainActor?.address?.city || '',
            country: declarationDetails?.stakeholders?.additionalSupplyChainActor?.address?.country || '',
          },
        },
      },
    },
    onSubmit: async (values) => {
      dispatch(
        declarationDetails.generalInfo.group === 'H1'
          ? saveDeclarationDataLRN({
              lrn: declarationDetails.messageInfo.LRN,
              sequence: declarationDetails.goodShipmentGlobal.sequence,
              partialDeclaration: values,
            })
          : savePartialDataToDeclaration(declarationDetails._id, values),
      );
    },
    enableReinitialize: false,
  });

  const verifyIntracomVat = async () => {
    dispatch(
      checkIntracomVat(declarationDetails.messageInfo.LRN, formik.values.stakeholders.intracom.identificationNumber),
    );
  };

  const verifyImporterEori = async () => {
    dispatch(
      checkImporterEori(declarationDetails.messageInfo.LRN, formik.values.stakeholders.importer.identificationNumber),
    );
  };

  const [importerEoriStatus, setImporterEoriStatus] = useState({ value: undefined, icon: StatusIcons.NOT_SENT });
  const [intracomVatStatus, setIntracomVatStatus] = useState({ value: undefined, icon: StatusIcons.NOT_SENT });

  useEffect(() => {
    if (declarationDetails.stakeholders.importer.evalEORI?.eoriOk === undefined) {
      setImporterEoriStatus({ value: undefined, icon: StatusIcons.NOT_SENT });
    } else if (declarationDetails.stakeholders.importer.evalEORI?.eoriOk) {
      setImporterEoriStatus({ value: true, icon: StatusIcons.RELEASED });
    } else {
      setImporterEoriStatus({ value: false, icon: StatusIcons.FAILED });
    }

    if (declarationDetails.stakeholders.intracom?.evalVAT?.vatOk === undefined) {
      setIntracomVatStatus({ value: undefined, icon: StatusIcons.NOT_SENT });
    } else if (declarationDetails.stakeholders.intracom?.evalVAT?.vatOk) {
      setIntracomVatStatus({ value: true, icon: StatusIcons.RELEASED });
    } else {
      setIntracomVatStatus({ value: false, icon: StatusIcons.FAILED });
    }
  }, [declarationDetails]);

  return (
    <FormContainer paddingRight={30} onSubmit={formik.handleSubmit}>
      <FormSection verticalPadding={20} topPadding={0} paddingBottom={0}>
        <FormSectionTitle spaceBetween>
          Exporter{' '}
          <Switcher
            onChange={(active) => formik.setFieldValue('stakeholders.exporter.header', active)}
            active={formik.values.stakeholders.exporter.header}
            label="Header"
          />
        </FormSectionTitle>
        <FormRow>
          <Input
            name="stakeholders.exporter.name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.exporter.name}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.exporter.identificationNumber"
            placeholder="Identification number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.exporter.identificationNumber}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="stakeholders.exporter.address.street"
            placeholder="Street and number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.exporter.address.street}
            width={40}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.exporter.address.postcode"
            placeholder="Postcode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.exporter.address.postcode}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.exporter.address.city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.exporter.address.city}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Country"
            fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('stakeholders.exporter.address.country', selectedOption?.value)
            }
            value={{
              value: formik.values.stakeholders.exporter.address.country,
              label: formik.values.stakeholders.exporter.address.country,
            }}
          />
        </FormRow>
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Importer</FormSectionTitle>
        <FormRow>
          <Input
            name="stakeholders.importer.name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.importer.name}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.importer.identificationNumber"
            placeholder="Identification number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.importer.identificationNumber}
            width={50}
            widthUnit="%"
            {...(declarationDetails.generalInfo.group === 'H1'
              ? {
                  picture: importerEoriStatus.icon,
                  isShowPicture: true,
                  isShowPictureDisabled: true,
                  onPictureClick: verifyImporterEori,
                }
              : {})}
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="stakeholders.importer.address.street"
            placeholder="Street and number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.importer.address.street}
            width={40}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.importer.address.postcode"
            placeholder="Postcode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.importer.address.postcode}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.importer.address.city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.importer.address.city}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Country"
            fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('stakeholders.importer.address.country', selectedOption?.value)
            }
            value={{
              value: formik.values.stakeholders.importer.address.country,
              label: formik.values.stakeholders.importer.address.country,
            }}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={0} paddingBottom={0}>
        <FormSectionTitle>Intracom</FormSectionTitle>
        <FormRow>
          <Input
            name="stakeholders.intracom.name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.intracom.name}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.intracom.identificationNumber"
            placeholder="Identification number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.intracom.identificationNumber}
            width={50}
            widthUnit="%"
            {...(declarationDetails.generalInfo.group === 'H1'
              ? {
                  picture: intracomVatStatus.icon,
                  isShowPicture: true,
                  isShowPictureDisabled: true,
                  onPictureClick: verifyIntracomVat,
                }
              : {})}
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="stakeholders.intracom.address.street"
            placeholder="Street and number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.intracom.address.street}
            width={40}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.intracom.address.postcode"
            placeholder="Postcode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.intracom.address.postcode}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.intracom.address.city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.intracom.address.city}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Country"
            fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('stakeholders.intracom.address.country', selectedOption?.value)
            }
            value={{
              value: formik.values.stakeholders.intracom.address.country,
              label: formik.values.stakeholders.intracom.address.country,
            }}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder spaceBetween>
          Additional supply chain actor{' '}
          <Switcher
            onChange={(active) => formik.setFieldValue('stakeholders.additionalSupplyChainActor.header', active)}
            active={formik.values.stakeholders.additionalSupplyChainActor.header}
            label="Header"
          />
        </FormSectionTitle>
        <FormRow>
          <Input
            name="stakeholders.additionalSupplyChainActor.name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.additionalSupplyChainActor.name}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.additionalSupplyChainActor.identificationNumber"
            placeholder="Identification number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.additionalSupplyChainActor.identificationNumber}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="stakeholders.additionalSupplyChainActor.address.street"
            placeholder="Street and number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.additionalSupplyChainActor.address.street}
            width={40}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.additionalSupplyChainActor.address.postcode"
            placeholder="Postcode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.additionalSupplyChainActor.address.postcode}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.additionalSupplyChainActor.address.city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.additionalSupplyChainActor.address.city}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Country"
            fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('stakeholders.additionalSupplyChainActor.address.country', selectedOption?.value)
            }
            value={{
              value: formik.values.stakeholders.additionalSupplyChainActor.address.country,
              label: formik.values.stakeholders.additionalSupplyChainActor.address.country,
            }}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Role"
            fetchOptions={(search: string) => retrieveCodes('CL704', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('stakeholders.additionalSupplyChainActor.role', selectedOption?.value)
            }
            value={{
              value: formik.values.stakeholders.additionalSupplyChainActor.role,
              label: formik.values.stakeholders.additionalSupplyChainActor.role,
            }}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder spaceBetween>
          Person providing a guarantee
        </FormSectionTitle>
        <FormRow>
          <Input
            placeholder="Identification number"
            value={declarationDetails.stakeholders?.personProvidingGuarantee?.identificationNumber}
            width={50}
            widthUnit="%"
            disabled={true}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder spaceBetween>
          Person paying the customs duty
        </FormSectionTitle>
        <FormRow>
          <Input
            placeholder="Identification number"
            value={declarationDetails.stakeholders?.personPayingTheCustomDuty?.identificationNumber}
            width={50}
            widthUnit="%"
            disabled={true}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={0} paddingBottom={0}>
        <FormSectionTitle>Declarant</FormSectionTitle>
        <FormRow>
          <Input
            disabled
            placeholder="Name"
            value={declarationDetails?.customsReport?.declarant?.name || declarationDetails?.customer?.declarant?.name}
          />
          <Input
            disabled
            placeholder="Identification number"
            value={
              declarationDetails?.customsReport?.declarant?.identificationNumber ||
              declarationDetails?.customer?.declarant?.identificationNumber
            }
          />
        </FormRow>
        <FormRow>
          <Input
            disabled
            placeholder="Street and number"
            value={
              declarationDetails?.customsReport?.declarant?.address?.street ||
              declarationDetails?.customer?.declarant?.address?.street
            }
          />
          <Input
            disabled
            placeholder="Postcode"
            value={
              declarationDetails?.customsReport?.declarant?.address?.postcode ||
              declarationDetails?.customer?.declarant?.address?.postcode
            }
          />
          <Input
            disabled
            placeholder="City"
            value={
              declarationDetails?.customsReport?.declarant?.address?.city ||
              declarationDetails?.customer?.declarant?.address?.city
            }
          />
          <Input
            disabled
            placeholder="Country"
            value={
              declarationDetails?.customsReport?.declarant?.address?.country ||
              declarationDetails?.customer?.declarant?.address?.country
            }
          />
        </FormRow>
        <FormSectionTitle noTopBorder>Contact person</FormSectionTitle>
        <FormRow>
          <Input
            disabled
            placeholder="Name"
            value={
              declarationDetails?.customsReport?.declarant?.contactPerson?.name ||
              declarationDetails?.customer?.declarant?.contactPerson?.name
            }
          />
          <Input
            disabled
            placeholder="Phone number"
            value={
              declarationDetails?.customsReport?.declarant?.contactPerson?.phoneNumber ||
              declarationDetails?.customer?.declarant?.contactPerson?.phoneNumber
            }
          />
          <Input
            disabled
            placeholder="Email"
            value={
              declarationDetails?.customsReport?.declarant?.contactPerson?.email ||
              declarationDetails?.customer?.declarant?.contactPerson?.email
            }
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={0} paddingBottom={0}>
        <FormSectionTitle>Representative</FormSectionTitle>
        <FormRow>
          <Input
            disabled
            placeholder="Name"
            value={
              declarationDetails?.customsReport?.declarant?.representative?.name ||
              declarationDetails?.customer?.representative?.name
            }
          />
          <Input
            disabled
            placeholder="Identification number"
            value={
              declarationDetails?.customsReport?.declarant?.representative?.identificationNumber ||
              declarationDetails?.customer?.representative?.identificationNumber
            }
          />
        </FormRow>
        <FormRow>
          <Input
            disabled
            placeholder="Street and number"
            value={
              declarationDetails?.customsReport?.declarant?.representative?.address?.street ||
              declarationDetails?.customer?.representative?.address?.street
            }
          />
          <Input
            disabled
            placeholder="Postcode"
            value={
              declarationDetails?.customsReport?.declarant?.representative?.address?.postcode ||
              declarationDetails?.customer?.representative?.address?.postcode
            }
          />
          <Input
            disabled
            placeholder="City"
            value={
              declarationDetails?.customsReport?.declarant?.representative?.address?.city ||
              declarationDetails?.customer?.representative?.address?.city
            }
          />
          <Input
            disabled
            placeholder="Country"
            value={
              declarationDetails?.customsReport?.declarant?.representative?.address?.country ||
              declarationDetails?.customer?.representative?.address?.country
            }
          />
        </FormRow>
      </FormSection>

      {!props.editDisabled && (
        <div>
          <Button type="submit" primary key="save-modal-btn">
            Save
          </Button>
        </div>
      )}
    </FormContainer>
  );
};

export default DeclarationDetailsStakeholders;
