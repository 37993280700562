/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

interface IPageContainerProps {
  isRelative?: boolean;
  overflowY?: boolean;
}

export const PageContainer = styled.div<IPageContainerProps>`
  ${tw`
    flex
    flex-col
    flex-1
    h-full
  `};
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'unset')};
  overflow-y: ${({ overflowY }) => (overflowY ? 'auto' : 'unset')};
`;

interface IPageHeaderProps {
  noTitle?: boolean;
}

export const PageHeader = styled.div<IPageHeaderProps>`
  ${tw`
    flex
    items-center
  `};
  justify-content: ${({ noTitle }) => (noTitle ? 'flex-end' : 'space-between')};
  padding: 0 ${({ theme: { spacing } }) => spacing.mainHorizontalPadding};
`;

export const PageTitle = styled.h2`
  font-size: 30px;
  line-height: 35px;
`;

interface ITabSelectorProps {
  isActive: boolean;
}

export const TableCellInLineContent = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TabSelector = styled.button<ITabSelectorProps>`
  ${tw`
    mr-8 
    inline-flex 
    items-center 
    px-2 
    py-4 
    font-medium 
    text-sm 
    leading-5 
    cursor-pointer 
    whitespace-nowrap
    outline-none
    focus:outline-none
  `};

  font-size: 15px;
  border-bottom-width: 3px;

  ${({
    isActive,
    theme: {
      colors: { tabs },
    },
  }) =>
    isActive
      ? css`
          color: ${tabs.active};
          border-color: ${tabs.active};
        `
      : css`
          color: ${tabs.inactive};
          border-color: transparent;
        `}
`;

interface ITabPanelContainerProps {
  isColumnView?: boolean;
  refactorModal?: boolean;
}

export const TabPanelContainer = styled.div<ITabPanelContainerProps>`
  ${tw`
    flex
    flex-col
    flex-1
    overflow-y-auto
    h-full
  `};
  padding: 0 ${({ theme: { spacing }, refactorModal }) => (refactorModal ? '0px' : spacing.mainHorizontalPadding)};

  & > div {
    height: calc(100% - ${({ isColumnView }) => (isColumnView ? '20px' : 0)});
  }
`;

export const DataTableControls = styled.div`
  ${tw`
    flex
    items-center
    justify-between
  `};

  button {
    margin-right: 20px;
  }
`;

interface IFormContainerProps {
  paddingRight?: number;
}

export const FormContainer = styled.form<IFormContainerProps>`
  ${tw`
    flex
    flex-col
  `}
  padding-right: ${({ paddingRight }) => paddingRight || 0}%;
`;

interface IFormSectionProps {
  noTopPadding?: boolean;
  verticalPadding?: number;
  topPadding?: number;
  paddingBottom?: number;
}

export const FormSection = styled.div<IFormSectionProps>`
  ${tw`
    flex
    flex-col
  `};
  padding: ${({ noTopPadding, verticalPadding }) =>
    noTopPadding
      ? `0 0 ${verticalPadding !== undefined ? verticalPadding : 40}px 0`
      : `${verticalPadding !== undefined ? verticalPadding : 40}px 0`};

  ${({ topPadding }) => {
    if (topPadding) {
      return css`
        padding-top: ${topPadding}px;
      `;
    }
  }}

  ${({ paddingBottom }) => {
    if (paddingBottom !== undefined) {
      return css`
        padding-bottom: ${paddingBottom}px;
      `;
    }
  }}
`;

export const FormRow = styled.div`
  ${tw`
    flex
  `};
  margin-bottom: 15px;

  & > div {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

interface IFormSectionTitleProps {
  noTopBorder?: boolean;
  spaceBetween?: boolean;
}

export const FormSectionTitle = styled.div<IFormSectionTitleProps>`
  ${tw`
    flex
    items-center
    font-extrabold
  `}
  border-top: ${({ noTopBorder }) => (noTopBorder ? 'none' : '1px solid #dedede')};
  padding: ${({ noTopBorder }) => (noTopBorder ? '10px 0 30px 0' : '25px 0 30px 0')};
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.36px;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'unset')};
`;

export const TableActionButton = styled.button`
  ${tw`
    outline-none
    focus:outline-none
  `}
`;

interface IFormCheckboxProps {
  hasDetails?: boolean;
}

export const FormCheckbox = styled.label<IFormCheckboxProps>`
  ${tw`
    flex
    items-center
    cursor-pointer
  `}

  flex-direction: ${({ hasDetails }) => (hasDetails ? 'column' : 'row')};

  ${({ hasDetails }) =>
    hasDetails &&
    css`
      align-items: flex-start;
    `}

  input {
    margin-right: 5px;
  }
  span {
    letter-spacing: 0.3px;
    color: #555454;
    font-weight: 700;
    font-size: 13px;
  }

  .details {
    font-style: italic;
    font-size: 12px;
    opacity: 0.6;
  }
`;

export const Error = styled.div`
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;
