/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
/* eslint-disable default-case */
/* eslint-disable complexity */
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

/* eslint-disable max-statements */
import { Batch, Declaration, DeclarationStatus } from '@e-origin/shared';

import backIcon from '../../assets/icons/back-icon.svg';
import { Input, Tab, Tabs } from '../../components';
import { fetchDeclarationDetails, selectDetails, setDetails } from '../../stores/declarationsSlice';
import { BackButton, CommonInputs, CommonInputsRow, Container, Header, Summary } from './declaration-details.style';
import DeclarationDetailsCustomsTreatment from './tabs/declaration-details-customs-treatment.component';
import DeclarationDetailsCustoms from './tabs/declaration-details-customs/declaration-details-customs.component';
import DeclarationDetailsDocuments from './tabs/declaration-details-documents.component';
import DeclarationDetailsDV1 from './tabs/declaration-details-dv1.component';
import DeclarationDetailsGeneralInfo from './tabs/declaration-details-general-info.component';
import DeclarationDetailsGoods from './tabs/declaration-details-goods.component';
import DeclarationDetailsStakeholders from './tabs/declaration-details-stakeholders.component';
import { DeclarationDetailsCustomsProof } from './tabs/declaration-details-customs-proof/declaration-details-customs-proof.component';
import { selectSettings } from '../../stores/settingsSlice';

const DeclarationDetails: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [editDisabled, setEditDisabled] = useState(false);

  const params = useParams() as any;

  const declarationDetails = useSelector(selectDetails);

  const settings = useSelector(selectSettings);

  const extractStatus = (details?: Declaration): string | undefined => {
    if (!details || !details.generalInfo) {
      return undefined;
    }
    switch (details.generalInfo.status) {
      case DeclarationStatus.NOT_SENT:
        return 'Not sent';
      case DeclarationStatus.SENT:
        return 'Sent';
      case DeclarationStatus.BEGATE_SENT:
        return 'BE-Gate Sent';
      case DeclarationStatus.BEGATE_RELEASED:
        return 'BE-Gate Released';
      case DeclarationStatus.BEGATE_NOT_RELEASED:
        return 'BE-Gate Not Released';
      case DeclarationStatus.SENDING:
        return 'Sending';
      case DeclarationStatus.ACCEPTED:
        return 'Accepted';
      case DeclarationStatus.INVALIDATED:
        return 'Invalidated';
      case DeclarationStatus.NOT_RELEASED:
        return 'Not released';
      case DeclarationStatus.REJECTED:
        return 'Rejected';
      case DeclarationStatus.RELEASED:
        return details.customsState?.invalidationRequest?.invalidationRequestDate &&
          details.customsState?.invalidationRequest?.invalidationRequestDate.length > 0
          ? 'Invalidation request'
          : 'Released';
    }
  };

  const status = useMemo(() => extractStatus(declarationDetails), [declarationDetails]);

  const isHighValue = declarationDetails?.generalInfo?.group === 'H1';

  useEffect(() => {
    dispatch(fetchDeclarationDetails(params.declarationId));
    // onDestroy
    return () => {
      dispatch(setDetails(null));
    };
  }, []);

  useEffect(() => {
    setEditDisabled(
      ![
        DeclarationStatus.ANALYSIS,
        DeclarationStatus.NOT_SENT,
        DeclarationStatus.BEGATE_SENT,
        DeclarationStatus.BEGATE_RELEASED,
        DeclarationStatus.BEGATE_NOT_RELEASED,
        DeclarationStatus.REJECTED,
      ].includes(declarationDetails?.generalInfo?.status as DeclarationStatus),
    );
  }, [declarationDetails]);

  return (
    <Container>
      <Header>
        <BackButton
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={backIcon} alt="" />
          Back
        </BackButton>
        <Summary>
          <div>
            <label>Batch name</label>
            <p>{(declarationDetails?.messageInfo?.batch as Batch)?.name}</p>
          </div>
          <div>
            <label>Status</label>
            <p className="status">{status}</p>
          </div>
        </Summary>
        <CommonInputs>
          <CommonInputsRow>
            <Input placeholder="Transport" value={declarationDetails?.messageInfo?.transport} disabled />
            <Input placeholder="LRN" value={declarationDetails?.messageInfo?.LRN} disabled />
            <Input placeholder="Sequence" value={declarationDetails?.goodShipmentGlobal?.sequence} disabled />
            {!isHighValue && (
              <Input
                placeholder="Deferred payment"
                value={declarationDetails?.messageInfo?.deferredPayment}
                disabled={editDisabled}
              />
            )}
          </CommonInputsRow>
          {isHighValue && (
            <CommonInputsRow>
              <Input
                placeholder="Total amount invoiced"
                value={declarationDetails?.goodShipmentGlobal?.totalAmountInvoiced || ''}
                disabled
              />
              <Input
                placeholder="Gross mass"
                value={declarationDetails?.goodShipmentGlobal?.totalGrossMass || ''}
                disabled
              />
              <Input
                placeholder="Net mass"
                value={declarationDetails?.goodShipmentGlobal?.totalNetMass || ''}
                disabled
              />
              <Input
                placeholder="Quantity"
                value={declarationDetails?.goodShipmentGlobal?.totalQuantity || ''}
                disabled
              />
              <Input
                placeholder="Number of packages"
                value={declarationDetails?.goodShipmentGlobal?.totalNumberOfPackages || ''}
                disabled
              />
            </CommonInputsRow>
          )}
        </CommonInputs>
      </Header>

      <Tabs style={{ padding: '0 50px', marginTop: 20 }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
        <Tab label="Goods Shipment">
          <DeclarationDetailsGoods editDisabled={editDisabled} generalInfo={declarationDetails?.generalInfo} />
        </Tab>
        <Tab label="General Info">
          <DeclarationDetailsGeneralInfo editDisabled={editDisabled} />
        </Tab>
        <Tab label="Stakeholders">
          <DeclarationDetailsStakeholders editDisabled={editDisabled} />
        </Tab>
        <Tab label="Documents">
          <DeclarationDetailsDocuments editDisabled={editDisabled} />
        </Tab>
        <Tab label="DV1">
          <DeclarationDetailsDV1 editDisabled={editDisabled} />
        </Tab>
        <Tab label="Customs Treatment">
          <DeclarationDetailsCustomsTreatment editDisabled={editDisabled} />
        </Tab>
        <Tab label="Customs State">
          <DeclarationDetailsCustoms status={status} />
        </Tab>
        {settings.walletEnabled && !isHighValue && (
          <Tab label="Customs Proof">
            <DeclarationDetailsCustomsProof status={status} />
          </Tab>
        )}
      </Tabs>
    </Container>
  );
};

export default DeclarationDetails;
