import styled from 'styled-components';

export const BadgeWrapper = styled.div`
  border-radius: 20px;
  color: #fff;
  padding: 8px 12px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
