import { useFormik } from 'formik';

import {
  Button,
  Input,
  IManageSupportingDocumentsRow,
  ManageSupportingDocumentsTable,
  Modal,
} from '../../../../../../components';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../../../styles/common';
import { ICommodityCodeRow } from '../../before-sending-batch-form-initializer';

interface IDeclarationDetailsDocumentsModalProps {
  onHide: () => void;
  commodityCode?: ICommodityCodeRow;
  onSave: (savedCommodityCode: ICommodityCodeRow) => void;
  disabled: boolean;
}

export const CommodityCodeModal: React.FC<IDeclarationDetailsDocumentsModalProps> = (props) => {
  const formik = useFormik<ICommodityCodeRow>({
    initialValues: props.commodityCode,
    onSubmit: (values) => {
      props.onSave(values);
      props.onHide();
    },
    enableReinitialize: false,
  });

  const startFormSubmit = () => {
    formik.submitForm();
  };

  const handleTableUpdate = (rows: IManageSupportingDocumentsRow[]) => {
    formik.setFieldValue('additionalReference', rows);
  };

  return (
    <Modal
      title={`Edit - HS Code ${props.commodityCode.firstItemData.hsCode}`}
      show={true}
      onHide={props.onHide}
      buttons={
        !props.disabled
          ? [
              <Button type="button" primary key="save-modal-btn" onClick={startFormSubmit}>
                Save
              </Button>,
            ]
          : []
      }
      buttonsJustifyAlign="center"
    >
      <FormContainer onSubmit={formik.handleSubmit}>
        <FormSection verticalPadding={0} topPadding={35}>
          <FormRow>
            <Input
              name="taricAdditionalCode"
              placeholder="Taric Additional Code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstItemData.taricAdditionalCode}
              width={100}
              widthUnit="%"
              disabled={props.disabled}
            />
          </FormRow>
          <FormRow>
            <Input
              name="nationalAdditionalCode"
              placeholder="National Additional Code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstItemData.nationalAdditionalCode}
              width={100}
              widthUnit="%"
              disabled={props.disabled}
            />
          </FormRow>
        </FormSection>
        <FormSection>
          <FormSectionTitle noTopBorder>Additional Reference Documents</FormSectionTitle>
          <ManageSupportingDocumentsTable
            documents={props.commodityCode.additionalReference}
            onChange={handleTableUpdate}
            createNewDocuments={true}
            disabled={props.disabled}
          />
        </FormSection>
      </FormContainer>
    </Modal>
  );
};
