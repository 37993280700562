import { Customer } from '@e-origin/shared';
import { FormikProps } from 'formik';
import { useParams } from 'react-router-dom';
import { Button, Input } from '../../../components';
import { EditCustomerType } from '../../../enums';
import { FormRow, FormSection, FormSectionTitle } from '../../../styles/common';
import { CustomerDetailsParamsType, CustomerDetailsTabType } from '../customer-details.component';
import * as Style from './customer-details-declarant.styles';

interface CustomerDetailsDeclarantProps extends CustomerDetailsTabType {
  form: FormikProps<Customer>;
}

const CustomerDetailsDeclarant: React.FC<CustomerDetailsDeclarantProps> = (props) => {
  const { handleSubmitCustomer, isButtonDisabled, form } = props;

  const { declarant } = form.values;

  const { handleBlur, handleChange } = form;
  const { customerId } = useParams<CustomerDetailsParamsType>();
  const context = customerId === 'add' ? 'CREATE' : 'UPDATE';

  return (
    <Style.Container>
      <FormSection>
        <FormRow>
          <Input
            name="declarant.name"
            placeholder="Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.name}
            width={387}
          />
          <Input
            name="declarant.identificationNumber"
            placeholder="Identification number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.identificationNumber}
            width={237}
          />
        </FormRow>
        <FormRow>
          <Input
            name="declarant.address.street"
            placeholder="Address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.address?.street}
            width={387}
          />
          <Input
            name="declarant.address.postcode"
            placeholder="Postcode"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.address?.postcode}
            width={180}
          />
          <Input
            name="declarant.address.city"
            placeholder="City"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.address?.city}
            width={180}
          />
          <Input
            name="declarant.address.country"
            placeholder="Country"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.address?.country}
            width={180}
          />
        </FormRow>
        <FormRow>
          <Input
            name="declarant.authorisedIdentity"
            placeholder="Authorised identity"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.authorisedIdentity}
            width={387}
          />
        </FormRow>
      </FormSection>
      <FormSection noTopPadding>
        <FormSectionTitle>Contact person</FormSectionTitle>
        <FormRow>
          <Input
            name="declarant.contactPerson.name"
            placeholder="Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.contactPerson?.name}
          />
          <Input
            name="declarant.contactPerson.phoneNumber"
            placeholder="Phone number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.contactPerson?.phoneNumber}
          />
          <Input
            name="declarant.contactPerson.email"
            placeholder="E-mail"
            onChange={handleChange}
            onBlur={handleBlur}
            value={declarant.contactPerson?.email}
          />
        </FormRow>
      </FormSection>
      <Style.SubmitSection>
        <Button
          disabled={isButtonDisabled}
          onClick={() => handleSubmitCustomer(EditCustomerType.DECLARANT)}
          primary
          type="submit"
        >
          {context === 'CREATE' ? 'Create' : 'Save'}
        </Button>
      </Style.SubmitSection>
    </Style.Container>
  );
};

export default CustomerDetailsDeclarant;
