import { omit } from 'lodash';

/* eslint-disable complexity */
import {
  AdditionalReference,
  Declaration,
  DeclarationDocumentsEnum,
  DeclarationGoods,
  IAdditionalCodesRiskAnalysis,
  RecursivePartial,
  TransactionTypes,
} from '@e-origin/shared';

export interface ICommodityCodeRow {
  riskAnalysis: IAdditionalCodesRiskAnalysis;
  firstItemData: {
    hsCode: string;
    nationalAdditionalCode: string;
    taricAdditionalCode: string;
  };
  additionalReference?: {
    selected: boolean;
    document: AdditionalReference;
  }[];
}

export interface IBeforeSendingFormData extends RecursivePartial<Declaration> {
  customer: any;
  commodityCodes: ICommodityCodeRow[];
  declarationGoods: RecursivePartial<DeclarationGoods>;
}

export const beforeSendingBatchFormIntializer = (declaration: Declaration): IBeforeSendingFormData => ({
  customer: declaration.customer,
  goodShipmentGlobal: {
    customsProcedureType: declaration.goodShipmentGlobal.customsProcedureType,
    customsNationalProcedure: declaration.goodShipmentGlobal.customsNationalProcedure,
    customsProcedure: declaration.goodShipmentGlobal.customsProcedure,
    customsNationalProcedureCode: declaration.goodShipmentGlobal.customsNationalProcedureCode,
    preference: declaration.goodShipmentGlobal.preference,
  },
  messageInfo: {
    declarationMainType: declaration.messageInfo.declarationMainType,
    declarationType: declaration.messageInfo.declarationType,
    methodOfPayment: declaration.messageInfo.methodOfPayment,
    deferredPayment: declaration.messageInfo.deferredPayment,
    methodOfPaymentVAT: declaration.messageInfo.methodOfPaymentVAT,
  },
  consignment: {
    countryOfDispatchHeader: declaration.consignment.countryOfDispatchHeader,
    countryOfDispatch: [TransactionTypes.B2B_HV, undefined].includes(declaration.transactionType)
      ? declaration.consignment.countryOfDispatch
      : undefined,
    destinationHeader: declaration.consignment.destinationHeader,
    countryOfDestination: declaration.consignment.countryOfDestination,
    regionOfDestination: declaration.consignment.regionOfDestination,
    locationOfGoods: {
      unLocode: declaration.consignment.locationOfGoods.unLocode,
    },
    customsOfficeExit: {
      referenceNumber: declaration.consignment.customsOfficeExit?.referenceNumber,
    },
    customsSupervisingOffice: {
      referenceNumber: declaration.consignment.customsSupervisingOffice?.referenceNumber,
    },
  },
  transportInformation: {
    inlandModeOfTransport: declaration.transportInformation?.inlandModeOfTransport,
    modeOfTransportMeansAtTheBorder: declaration.transportInformation?.modeOfTransportMeansAtTheBorder,
    arrivalTransportMeans: {
      identificationNumber: declaration.transportInformation?.arrivalTransportMeans?.identificationNumber,
      typeOfIdentification: declaration.transportInformation?.arrivalTransportMeans?.typeOfIdentification,
    },
    departureTransportMeans: {
      identificationNumber: declaration.transportInformation?.departureTransportMeans?.identificationNumber,
    },
    activeBorderTransportMeans: {
      nationality: declaration.transportInformation?.activeBorderTransportMeans?.nationality,
      identificationNumber: declaration.transportInformation?.activeBorderTransportMeans?.identificationNumber,
    },
    transportEquipment: {
      header: declaration.transportInformation?.transportEquipment?.header,
      containers: declaration.transportInformation?.transportEquipment?.containers?.length
        ? declaration.transportInformation?.transportEquipment?.containers
        : [
            {
              identificationNumber: declaration.transportInformation?.transportEquipment?.containerIdentification || '',
            },
          ],
      containerIdentification: declaration.transportInformation?.transportEquipment?.containerIdentification,
      seals: declaration.transportInformation?.transportEquipment?.seals?.length
        ? declaration.transportInformation?.transportEquipment?.seals
        : [{ identificationNumber: '' }],
    },
    countryOfRoutingOfConsignment: declaration.transportInformation?.countryOfRoutingOfConsignment?.length
      ? declaration.transportInformation?.countryOfRoutingOfConsignment
      : [{ country: '' }],
  },
  valuationInformationTaxes: {
    deliveryTerms: {
      incotermCode: declaration.valuationInformationTaxes?.deliveryTerms?.incotermCode,
      location: declaration.valuationInformationTaxes?.deliveryTerms?.location,
    },
    chargesimport: {
      CustomsCharges: {
        charges: declaration.globalInfo.valuationInformationTaxes?.chargesimport?.CustomsCharges?.charges || 0,
        currency: declaration.globalInfo.valuationInformationTaxes?.chargesimport?.CustomsCharges?.currency,
      },
      VATcharges: {
        charges: declaration.globalInfo.valuationInformationTaxes?.chargesimport?.VATcharges?.charges || 0,
        currency: declaration.globalInfo.valuationInformationTaxes?.chargesimport?.VATcharges?.currency,
      },
    },
  },
  documents: (() => {
    const documents = omit(
      declaration.documents,
      declaration.transactionType === TransactionTypes.B2C_HV_BEGATE
        ? [DeclarationDocumentsEnum.PREVIOUS_DOCUMENTS]
        : [],
    );
    return Object.keys(documents).reduce((acc, docType) => {
      return {
        ...acc,
        [docType]: (documents[docType] || [])
          .map((doc) => ({
            ...doc,
            header: doc.header === undefined ? true : doc.header,
          }))
          .filter((doc) => doc.header),
      };
    }, {});
  })(),
  declarationGoods: [TransactionTypes.B2B_HV, undefined].includes(declaration.transactionType)
    ? {
        packaging: {
          typeOfPackages: declaration.goodsShipmentItems[0].packaging.typeOfPackages,
        },
      }
    : undefined,
  commodityCodes: declaration.globalInfo.goodShipmentGlobals.map((goodShipmentGlobal) => ({
    riskAnalysis: {
      hsCodeFound: goodShipmentGlobal.hsCodeFound,
      taricData: {},
    },
    firstItemData: {
      hsCode: goodShipmentGlobal.firstItemData.hsCode,
      nationalAdditionalCode: goodShipmentGlobal.firstItemData.nationalAdditionalCode,
      taricAdditionalCode: goodShipmentGlobal.firstItemData.taricAdditionalCode,
    },
    supportingDocuments: [],
  })),
});
