import { useRef } from 'react';

import { DeclarationStatus } from '@e-origin/shared';

import * as Style from './declaration-status-badge.style';

interface IDeclarationStatusBadgeProps {
  status: DeclarationStatus;
}

export const DeclarationStatusBadge: React.FC<IDeclarationStatusBadgeProps> = (props) => {
  const statusMap = useRef({
    [DeclarationStatus.ANALYSIS]: { label: 'Risk Analysis', color: '#ECCC38' },
    [DeclarationStatus.NOT_SENT]: { label: 'Not Sent', color: '#212121' },
    [DeclarationStatus.SENDING]: { label: 'Sending', color: '#212121' },
    [DeclarationStatus.BEGATE_SENT]: { label: 'BE-Gate Sent', color: '#212121' },
    [DeclarationStatus.BEGATE_NOT_RELEASED]: { label: 'Be-Gate Not Released', color: '#FF3D3D' },
    [DeclarationStatus.BEGATE_RELEASED]: { label: 'Be-Gate Released', color: '#29AA47' },
    [DeclarationStatus.SENT]: { label: 'Sent', color: '#212121' },
    [DeclarationStatus.ACCEPTED]: { label: 'Validation In Progress', color: '#979797' },
    [DeclarationStatus.REJECTED]: { label: 'Rejected', color: '#FF6C0D' },
    [DeclarationStatus.IN_CONTROL]: { label: 'In Control', color: '#FF3D3D' },
    [DeclarationStatus.NOT_RELEASED]: { label: 'Not Released', color: '#FF3D3D' },
    [DeclarationStatus.RELEASED]: { label: 'Released', color: '#29AA47' },
    [DeclarationStatus.INVALIDATED]: { label: 'Invalidated', color: '#0F6298' },
  });
  return (
    <Style.BadgeWrapper style={{ backgroundColor: statusMap.current[props.status]?.color }}>
      {statusMap.current[props.status].label}
    </Style.BadgeWrapper>
  );
};
