/* eslint-disable max-classes-per-file */
import { modelOptions, prop, Ref, Severity } from '@typegoose/typegoose';

import { TemplateType } from '../enums';
import { BaseModel } from './base.model';
import { BatchStatistics } from './batch.statistics';
import { Customer } from './customer';
import { Declarant } from './declarant';
import { ValuationInformationTaxes } from './declaration';
import { DomainCollection } from './domain.collection';
import { Template } from './template';
import { User } from './user';

export class BatchStatus {
  @prop({ required: true })
  value: string;

  @prop()
  details?: string;

  @prop()
  subStatus?: string;
}

export class BatchRiskAnalysis {
  @prop()
  impactInEuro: number;

  @prop()
  impactInPercentage: number;

  @prop()
  totalAmountForDutiesAndTaxes: number;

  @prop()
  currentAmountForDutiesAndTaxesSum?: number;

  @prop()
  initialAmountToPayForDuties?: number;
}

export class BatchComment {
  @prop()
  value: string;

  @prop()
  status: string;

  @prop({ default: Date.now })
  date: Date;

  @prop({ ref: () => User })
  createdBy: Ref<User>;
}

export class BatchDataChanges {
  @prop()
  fieldName: string;

  @prop()
  oldFieldValue: string;

  @prop()
  newFieldValue: string;
}

export class BatchTrace {
  @prop({ ref: () => User })
  userId: Ref<User>;

  @prop()
  userName: string;

  @prop()
  dateTime: string;

  @prop({ _id: false, type: () => [BatchDataChanges] })
  dataChanges: BatchDataChanges[];
}

export class BatchCreate {
  name: string;

  customer: string;

  template: string;

  file?: File | null;
}

export class BatchProgress {
  status: string;

  value: number;

  totalWebUrls?: number;

  processedUrls?: number;
}

export class CloudBucketFilesUrls {
  [id: string]: string | CloudBucketFilesUrls;
}

export class BatchSummary {
  @prop()
  totalGrossMass: number;
}

@modelOptions({ options: { allowMixed: Severity.ALLOW } })
@DomainCollection({ schemaOptions: { timestamps: true } })
export class Batch extends BaseModel {
  @prop({ required: true })
  name: string;

  @prop({ required: true })
  counter: number;

  @prop({ required: true })
  filename: string;

  @prop({ ref: () => Customer })
  customer: Ref<Customer>;

  @prop({ ref: () => Template })
  template: Ref<Template>;

  @prop({ enum: [TemplateType.LOW_VALUE_H7, TemplateType.HIGH_VALUE_H1], default: TemplateType.LOW_VALUE_H7 })
  templateType: TemplateType;

  /**
   * ony lfor high values declarations
   */
  @prop()
  isExport?: boolean;

  @prop()
  transactionType: number;

  @prop({ _id: false, type: BatchStatus })
  status: BatchStatus;

  @prop({ ref: () => Declarant })
  declarant: Ref<Declarant>;

  @prop({ _id: false, type: [Object] })
  cloudBucketFilesUrls: CloudBucketFilesUrls;

  @prop()
  cloudBucketFileName: string;

  @prop()
  cloudBucketInvoiceUrl: string;

  @prop()
  cloudBucketBeGateFile: string;

  @prop({ default: false })
  deleted: boolean;

  @prop()
  riskAnalysis: BatchRiskAnalysis;

  @prop({ _id: false, type: BatchStatistics })
  statistics: BatchStatistics;

  @prop({ _id: false, type: BatchSummary })
  summary?: BatchSummary;

  @prop({ _id: false, type: [BatchComment] })
  comments: BatchComment[];

  @prop({ default: false })
  allDeclarationsSent: boolean;

  @prop({ required: true, ref: () => User })
  createdBy: Ref<User>;

  @prop({ _id: false, type: [BatchTrace] })
  trace: BatchTrace[];

  @prop({ required: false })
  canCleanup: boolean;

  // todo: delete this field from the mongodb & code
  @prop({ required: false })
  canDelete: boolean;

  @prop({ _id: false, type: ValuationInformationTaxes, default: {} })
  valuationInformationTaxes: ValuationInformationTaxes;

  hasFileInCloud: boolean;

  isCleaningUp: boolean;

  progress?: BatchProgress;

  static cleanName(value: string): string {
    return value.replace(/[^a-zA-Z0-9-]/, '');
  }

  // used in atlas search pagination
  paginationToken?: string;
}
