/* eslint-disable max-statements */
/* eslint-disable default-case */
import { useDispatch, useSelector } from 'react-redux';

import { Batch, DeclarationCustomsFlowType, DeclarationStatus, getTotal, TemplateType } from '@e-origin/shared';

import { Button, CustomModal } from '../';
import Icons from '../../assets';
import { useConfig } from '../../hooks/use-config';
import { sendAllDeclarationsToIDMS, sendAllDeclarationsToPLDA, sendBatchToBeGate } from '../../stores/batchesSlice';
import { selectDeclarantSendingDelaySeconds } from '../../stores/declarantSlice';
import { selectSettings } from '../../stores/settingsSlice';
import ModalStyle from '../../styles/modal';
import * as Style from './send-declaration-modal.styles';

interface ISendDeclarationModalProps {
  batch: Batch;
  onHide(): void;
}

enum SendBatchOptionsEnum {
  BE_GATE = 'BE_GATE',
  PLDA = 'PLDA',
  IDMS = 'IDMS',
  PRE_DECO = 'PRE_DECO',
  POST_DECO = 'POST_DECO',
}

// eslint-disable-next-line complexity
export const SendBatchModal: React.FC<ISendDeclarationModalProps> = (props) => {
  const dispatch = useDispatch();

  const { config } = useConfig();

  const sendingDelaySeconds = useSelector(selectDeclarantSendingDelaySeconds);

  const settings = useSelector(selectSettings);

  const handleBatchSending = (option: SendBatchOptionsEnum) => {
    // eslint-disable-next-line complexity
    setTimeout(() => {
      switch (option) {
        case SendBatchOptionsEnum.BE_GATE: {
          dispatch(sendBatchToBeGate(props.batch._id));
          break;
        }
        case SendBatchOptionsEnum.PLDA: {
          dispatch(sendAllDeclarationsToPLDA(props.batch._id));
          break;
        }
        case SendBatchOptionsEnum.IDMS: {
          dispatch(sendAllDeclarationsToIDMS(props.batch._id));
          break;
        }
        case SendBatchOptionsEnum.PRE_DECO: {
          dispatch(sendAllDeclarationsToIDMS(props.batch._id, null, DeclarationCustomsFlowType.PRE));
          break;
        }
        case SendBatchOptionsEnum.POST_DECO: {
          dispatch(sendAllDeclarationsToIDMS(props.batch._id, null, DeclarationCustomsFlowType.POST));
          break;
        }
      }
      props.onHide();
      // use a random interval to prevent double sending if another user clicks at the same time on send button
    }, (Math.random() + Math.random()) * 1000);
  };

  const showPre = (b: Batch) =>
    getTotal(b, DeclarationStatus.NOT_SENT, DeclarationCustomsFlowType.PRE) > 0 ||
    getTotal(b, DeclarationStatus.ANALYSIS, DeclarationCustomsFlowType.PRE) > 0 ||
    getTotal(b, DeclarationStatus.REJECTED, DeclarationCustomsFlowType.PRE) > 0;

  const showPost = (b: Batch) =>
    getTotal(b, DeclarationStatus.ACCEPTED, DeclarationCustomsFlowType.PRE) > 0 ||
    getTotal(b, DeclarationStatus.IN_CONTROL, DeclarationCustomsFlowType.PRE) > 0 ||
    getTotal(b, DeclarationStatus.REJECTED, DeclarationCustomsFlowType.POST) > 0 ||
    getTotal(b, DeclarationStatus.IN_CONTROL, DeclarationCustomsFlowType.POST) > 0;

  return (
    <CustomModal show={true} onHide={props.onHide}>
      <ModalStyle.Header>
        <Style.TitleContainer>
          <ModalStyle.Title>Send Batch</ModalStyle.Title>
          <Style.BatchName>{props.batch?.name}</Style.BatchName>
        </Style.TitleContainer>
        <ModalStyle.HeaderActions>
          <button onClick={props.onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      {(props.batch.templateType === TemplateType.HIGH_VALUE_H1 && config?.COUNTRY === 'BE' && (
        <ModalStyle.Content>
          <Style.ButtonsContainer>
            <Button
              type="button"
              primary
              onClick={() => handleBatchSending(SendBatchOptionsEnum.BE_GATE)}
              disabled={props.batch.statistics.beGateSent > 0}
            >
              BE-GATE
            </Button>
            <Button type="button" primary onClick={() => handleBatchSending(SendBatchOptionsEnum.PLDA)}>
              PLDA
            </Button>
            {settings.highValuesIDMSEnabled && (
              <Button type="button" primary onClick={() => handleBatchSending(SendBatchOptionsEnum.IDMS)}>
                IDMS
              </Button>
            )}
          </Style.ButtonsContainer>
        </ModalStyle.Content>
      )) ||
        ''}

      {(props.batch.templateType === TemplateType.HIGH_VALUE_H1 && config?.COUNTRY === 'NL' && (
        <ModalStyle.Content>
          <Style.ButtonsContainer>
            {showPre(props.batch) && (
              <Button type="button" primary onClick={() => handleBatchSending(SendBatchOptionsEnum.PRE_DECO)}>
                Send PRE
              </Button>
            )}
            {showPost(props.batch) && (
              <Button type="button" primary onClick={() => handleBatchSending(SendBatchOptionsEnum.POST_DECO)}>
                Send POST
              </Button>
            )}
          </Style.ButtonsContainer>
        </ModalStyle.Content>
      )) ||
        ''}

      {(props.batch.templateType !== TemplateType.HIGH_VALUE_H1 && (
        <>
          <ModalStyle.Content style={{ padding: '30px 45px' }}>
            <Style.Description>
              Are you sure you want to send all declarations ({props.batch.statistics.total}) for this batch?
            </Style.Description>
            <Style.Remark>
              NOTE: Declarations are sent {sendingDelaySeconds} seconds apart one from another.
            </Style.Remark>
          </ModalStyle.Content>
          <ModalStyle.Footer>
            <Button type="button" outline onClick={props.onHide}>
              Cancel
            </Button>
            {config?.COUNTRY === 'BE' && (
              <Button type="button" primary onClick={() => handleBatchSending(SendBatchOptionsEnum.IDMS)}>
                Send
              </Button>
            )}
            {config?.COUNTRY === 'NL' && showPre(props.batch) && (
              <Button type="button" primary onClick={() => handleBatchSending(SendBatchOptionsEnum.PRE_DECO)}>
                Send PRE
              </Button>
            )}
            {config?.COUNTRY === 'NL' && showPost(props.batch) && (
              <Button type="button" primary onClick={() => handleBatchSending(SendBatchOptionsEnum.POST_DECO)}>
                Send POST
              </Button>
            )}
          </ModalStyle.Footer>
        </>
      )) ||
        ''}
    </CustomModal>
  );
};
