/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable default-case */
import { FormikProps } from 'formik';
import { cloneDeep } from 'lodash';

import { Declaration, DeclarationDocumentsEnum, Documents, TransactionTypes } from '@e-origin/shared';

import {
  DeclarationDocumentsComponent,
  DocumentChangeProps,
  getDeclarationDocumentById,
  getDeclarationDocumentPos,
} from '../../../../../components';
import { MutateDeclarationDocumentOperation } from '../../../../../stores/declarationsSlice';
import { IBeforeSendingFormData } from '../before-sending-batch-form-initializer';

interface IFinalizeDeclarationDocumentsProps {
  form: FormikProps<IBeforeSendingFormData>;
  declaration: Declaration;
  disabled: boolean;
}
export const FinalizeDeclarationDocuments: React.FC<IFinalizeDeclarationDocumentsProps> = (props) => {
  const { form, declaration } = props;

  const handleDocuments = (doc: DocumentChangeProps) => {
    const refreshedDocuments = cloneDeep(form.values.documents);

    switch (doc.operation) {
      case MutateDeclarationDocumentOperation.CREATE: {
        refreshedDocuments[doc.type] = [...(refreshedDocuments[doc.type] || []), doc.data];
        break;
      }
      case MutateDeclarationDocumentOperation.UPDATE: {
        const documentToUpdate = getDeclarationDocumentById(doc.id, refreshedDocuments[doc.type]);
        const documentPos = refreshedDocuments[doc.type].findIndex((d) => d === documentToUpdate);
        refreshedDocuments[doc.type][documentPos] = doc.data;
        break;
      }
      case MutateDeclarationDocumentOperation.DELETE: {
        const index = getDeclarationDocumentPos(doc.data, doc.type, refreshedDocuments[doc.type]);

        if (index !== -1) {
          refreshedDocuments[doc.type].splice(index, 1);
        }
        break;
      }
    }

    form.setFieldValue('documents', refreshedDocuments);
  };

  return (
    <DeclarationDocumentsComponent
      documents={form.values.documents as Documents}
      editDisabled={props.disabled}
      excludeDocumentTypes={
        declaration.transactionType === TransactionTypes.B2C_HV_BEGATE
          ? [DeclarationDocumentsEnum.PREVIOUS_DOCUMENTS]
          : []
      }
      onDocumentChange={handleDocuments}
      defaultHeaderValue={true}
      headerDisabled={true}
      isHighValue={declaration.generalInfo.group === 'H1'}
    />
  );
};
